import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';

import { RoutingService } from '../services/routing-service/routing.service';

export function isPersonalisedExposeGuard(): CanMatchFn {
  return (route, segments) => {
    const urlSlug = segments[0].path;
    const routingService = inject(RoutingService);
    return routingService.routeToExposeLink(urlSlug);
  };
}

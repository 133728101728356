import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateModuleConfig,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import germanLocale from '@angular/common/locales/de';
import * as isoCountries from 'i18n-iso-countries';
import isoCountriesDE from 'i18n-iso-countries/langs/de.json';
import isoCountriesEN from 'i18n-iso-countries/langs/de.json';

import { routes } from './app.routes';

registerLocaleData(germanLocale);
isoCountries.registerLocale(isoCountriesDE);
isoCountries.registerLocale(isoCountriesEN);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

const translateModuleConfig: TranslateModuleConfig = {
  defaultLanguage: 'de',
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient],
  },
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(),
    importProvidersFrom(
      BrowserModule,
      TranslateModule.forRoot(translateModuleConfig),
    ),
  ],
};

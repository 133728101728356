@if (emproSpinnerService.loading(); as loader) {
  <empro-spinner
    [show]="loader.show"
    [duration]="loader.duration"
    [showOverlay]="loader.showOverlay"
    [size]="loader.size"
    [variant]="loader.variant"
  />
}

<router-outlet />

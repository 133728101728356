import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import {
  EmproSpinnerComponent,
  EmproSpinnerService,
} from '@immosolve/empro-shared-ui';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [RouterOutlet, EmproSpinnerComponent],
})
export class AppComponent {
  constructor(public emproSpinnerService: EmproSpinnerService) {}
}
